import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import MatchGridLayout from "../components/Match-Container/MatchGridLayout";
import HeadingWithGrid from "../components/Headings/HeadingWithGrid";
import MessageBoxSimple from "../components/Message-Box/MessageBoxSimple";
import { useSelector } from "react-redux";

const GulfCup = () => {
  const { lang } = useSelector((state) => state.langSlice);
  return (
    <Layout active={10}>
      <Drawer active={10} />
      <MatchGridLayout>
        <HeadingWithGrid
          title={
            lang == "english"
              ? "Arabian Gulf Cup"
              : lang == "spanish"
              ? "Copa del Golfo Arábigo"
              : lang == "french"
              ? "Coupe du Golfe Arabique"
              : lang == "arabic"
              ? "كأس الخليج العربي"
              : "Arabian Gulf Cup"
          }
          type="New Season Update"
        />
        <MessageBoxSimple
          title={
            lang == "english"
              ? "New Season Starting from 21st December 2024"
              : lang == "spanish"
              ? "Nueva temporada a partir del 21 de diciembre de 2024"
              : lang == "french"
              ? "Nouvelle saison à partir du 21 décembre 2024"
              : lang == "arabic"
              ? "الموسم الجديد يبدأ من 21 ديسمبر 2024"
              : "New Season Starting from 21st December 2024"
          }
          type="New Season Update"
        />
      </MatchGridLayout>
    </Layout>
  );
};

export default GulfCup;
