import React, { useEffect, useState } from "react";
import MatchLayout from "../components/MatchLayout";
import Loader from "../components/Loader";
import Drawer from "../components/Drawer";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import {
  baseUrl,
  matchByIdApi,
  matchPredictionApi,
  matchStatisticsApi,
  teamLineupsApi,
} from "../api/api";
import { toast } from "react-toastify";
import classes from "../css/Match.module.css";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import LastMatchesResults from "../components/LastMatchesResults";
import MatchEvents from "../components/MatchEvents";
import Commentry from "../components/Commentry";
import MatchComparison from "../components/MatchComparison";
import TeamLineups from "../components/TeamLineups";
import PlayerInfoModal from "../components/PlayerInfoModal";
import { useSelector } from "react-redux";
import MatchGridCenterLayout from "../components/Match-Container/MatchGridCenterLayout";

const MatchPage = () => {
  const { id, homeTeamId, awayTeamId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [matchPrediction, setMatchPrediction] = useState();
  const [homeTeamLineups, setHomeTeamLineups] = useState([]);
  const [awayTeamLineups, setAwayTeamLineups] = useState([]);
  const [teamStatistics, setTeamStatistics] = useState([]);
  const [teamStatisticsTrue, setTeamStatisticsTrue] = useState([]);
  const [homeTeamStatistics, setHomeTeamStatistics] = useState([]);
  const [awayTeamStatistics, setAwayTeamStatistics] = useState([]);
  const [homeTeamLineups2, setHomeTeamLineups2] = useState([]);
  const [awayTeamLineups2, setAwayTeamLineups2] = useState([]);
  const [homeTeamEvents2, setHomeTeamEvents2] = useState([]);
  const [awayTeamEvents2, setAwayTeamEvents2] = useState([]);
  const [homeTeamName, setHomeTeamName] = useState("");
  const [awayTeamName, setAwayTeamName] = useState("");

  const { lang } = useSelector((state) => state.langSlice);

  const fetchMatchPredictionFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseUrl}${matchPredictionApi}${id}`);

      setMatchPrediction(res?.data?.response);
      setLoading(false);
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };
  const fetchHomeTeamLineups = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const res = await axios.get(
        `${baseUrl}${teamLineupsApi}/${id}/${homeTeamId}`
      );

      setHomeTeamLineups(res?.data?.response);

      // setLoading(false);
      return res?.data?.response;
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };
  const fetchAwayTeamLineups = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const res = await axios.get(
        `${baseUrl}${teamLineupsApi}/${id}/${awayTeamId}`
      );
      setAwayTeamLineups(res?.data?.response);

      // setLoading(false);
      return res?.data?.response;
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  const fetchMatchStatistics = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }

      const res = await axios.get(
        `${baseUrl}${matchStatisticsApi}${id}/${homeTeamId}/${awayTeamId}`
      );
      setTeamStatisticsTrue(res?.data?.response);
      setTeamStatistics(res?.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error in match statistics");
    }
  };

  const fetchDataFromBackend = async (loader) => {
    // something wrong here ....
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const res = await axios.get(`${baseUrl}${matchByIdApi}${id}`);
      setData(res?.data?.message[0]);

      const homeTeam = res?.data?.message[0]?.homeTeam;
      setHomeTeamName(homeTeam);
      const awayTeam = res?.data?.message[0]?.awayTeam;
      setAwayTeamName(awayTeam);

      const homeTeamEvents = res?.data?.message[0]?.events?.filter((data) => {
        return (
          data?.team?.name == homeTeam &&
          data?.type == "Goal" &&
          data?.detail != "Missed Penalty"
        );
      });

      setHomeTeamEvents2(homeTeamEvents);
      const awayTeamEvents = res?.data?.message[0]?.events?.filter((data) => {
        return (
          data?.team?.name == awayTeam &&
          data?.type == "Goal" &&
          data?.detail != "Missed Penalty"
        );
      });

      setAwayTeamEvents2(awayTeamEvents);

      const hte = homeTeamEvents?.map((data) => data?.player?.name);
      const ate = awayTeamEvents?.map((data) => data?.player?.name);

      const homeLineups = await fetchHomeTeamLineups({
        loader: loader?.loading,
      });
      const awayLineups = await fetchAwayTeamLineups({
        loader: loader?.loading,
      });

      // const newArrayHTE = homeTeamLineups?.map((team) => {
      const newArrayHTE = homeLineups?.map((team) => {
        const updatedStartXI = team.startXI.map((playerObj) => {
          const playerName = playerObj.player.name;
          const count = hte?.filter((name) => name === playerName).length;
          return {
            ...playerObj,
            player: {
              ...playerObj.player,
              count: count > 0 ? count : 0,
            },
          };
        });

        const updatedSubstitutes = team.substitutes.map((playerObj) => {
          const playerName = playerObj.player.name;
          const count = hte?.filter((name) => name === playerName).length;
          return {
            ...playerObj,
            player: {
              ...playerObj.player,
              count: count > 0 ? count : 0,
            },
          };
        });

        return {
          ...team,
          startXI: updatedStartXI,
          substitutes: updatedSubstitutes,
        };
      });

      setHomeTeamLineups2(newArrayHTE);

      // const newArrayATE = awayTeamLineups?.map((team) => {
      const newArrayATE = awayLineups?.map((team) => {
        const updatedStartXI = team.startXI.map((playerObj) => {
          const playerName = playerObj.player.name;
          const count = ate?.filter((name) => name === playerName).length;
          return {
            ...playerObj,
            player: {
              ...playerObj.player,
              count: count > 0 ? count : 0,
            },
          };
        });

        const updatedSubstitutes = team.substitutes.map((playerObj) => {
          const playerName = playerObj.player.name;
          const count = ate?.filter((name) => name === playerName).length;
          return {
            ...playerObj,
            player: {
              ...playerObj.player,
              count: count > 0 ? count : 0,
            },
          };
        });

        return {
          ...team,
          startXI: updatedStartXI,
          substitutes: updatedSubstitutes,
        };
      });

      setAwayTeamLineups2(newArrayATE);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  useEffect(() => {
    // fetchHomeTeamLineups();
    // fetchAwayTeamLineups();
    fetchDataFromBackend({ loading: true });
    fetchMatchStatistics({ loading: true });
    fetchMatchPredictionFromBackend();
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
        fetchMatchStatistics({ loading: false });
      }
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout>
      <Drawer />
      {loading ? (
        <Loader />
      ) : (
        <>
          <MatchGridCenterLayout>
            <MatchLayout match={data} hideButton={true} />
          </MatchGridCenterLayout>

          <div className={classes.tab_container}>
            <TabView scrollable>
              <TabPanel
                header={
                  lang == "english"
                    ? "Line ups"
                    : lang == "spanish"
                    ? "alineaciones"
                    : lang == "french"
                    ? "Les files d'attente"
                    : lang == "arabic"
                    ? "الاصطفافات"
                    : "Line ups"
                }
              >
                {homeTeamLineups2?.length > 0 && awayTeamLineups2.length > 0 ? (
                  <div className={classes.line_up_container}>
                    <div className={classes.home_team_info}>
                      <div className={classes.home_team_info_sub}>
                        <img
                          src={homeTeamLineups[0]?.team?.logo}
                          className={classes.home_team_logo}
                          alt={homeTeamLineups[0]?.team?.name}
                        />
                        <p className={classes.home_team_name}>
                          {homeTeamLineups[0]?.team?.name}
                        </p>
                      </div>

                      <p className={classes.home_team_formation}>
                        {homeTeamLineups[0]?.formation}
                      </p>
                    </div>
                    <div className={classes.line_up_sub_container}>
                      <img
                        src="/assets/images/football_ground.jpg"
                        alt="football ground"
                        className={classes.ground}
                      />
                      <div className={classes.home_team}>
                        <TeamLineups
                          homeTeam={true}
                          team={homeTeamLineups[0]?.team}
                          coach={homeTeamLineups[0]?.coach}
                          // startXI={homeTeamLineups[0]?.startXI}
                          startXI={homeTeamLineups2[0]?.startXI}
                          formation={homeTeamLineups[0]?.formation}
                        />
                      </div>
                      <div className={classes.away_team}>
                        <TeamLineups
                          homeTeam={false}
                          team={awayTeamLineups[0]?.team}
                          coach={awayTeamLineups[0]?.coach}
                          // startXI={awayTeamLineups[0]?.startXI}
                          startXI={awayTeamLineups2[0]?.startXI}
                          formation={awayTeamLineups[0]?.formation}
                        />
                      </div>
                    </div>
                    <div className={classes.home_team_info}>
                      <div className={classes.home_team_info_sub}>
                        <img
                          src={awayTeamLineups[0]?.team?.logo}
                          className={classes.home_team_logo}
                          alt={awayTeamLineups[0]?.team?.name}
                        />
                        <p className={classes.home_team_name}>
                          {awayTeamLineups[0]?.team?.name}
                        </p>
                      </div>

                      <p className={classes.home_team_formation}>
                        {awayTeamLineups[0]?.formation}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={classes.message_box}>
                    <p>
                      {lang == "english"
                        ? "Match Lineups will appear 15-30 minutes before the match starts"
                        : lang == "spanish"
                        ? "Las alineaciones de los partidos aparecerán entre 15 y 30 minutos antes de que comience el partido."
                        : lang == "french"
                        ? "Les compositions de match apparaîtront 15 à 30 minutes avant le début du match."
                        : lang == "arabic"
                        ? "ستظهر تشكيلات المباراة قبل 15-30 دقيقة من بدء المباراة"
                        : "Match Lineups will appear 15-30 minutes before the match starts"}
                    </p>
                  </div>
                )}
              </TabPanel>
              <TabPanel
                header={
                  lang == "english"
                    ? "Stats"
                    : lang == "spanish"
                    ? "Estadísticas"
                    : lang == "french"
                    ? "Statistiques"
                    : lang == "arabic"
                    ? "احصائيات"
                    : "Stats"
                }
              >
                {teamStatisticsTrue?.length > 0 && (
                  <div className={classes.stats_container}>
                    <div className={classes.teams_info}>
                      <div className={classes.home_team_container}>
                        <img
                          src={teamStatistics?.homeTeamLogo}
                          className={classes.team_logo}
                          alt={homeTeamStatistics[0]?.team?.name}
                        />
                        <p className={classes.name}>
                          {teamStatistics?.homeTeamName}
                        </p>
                      </div>
                      <div className={classes.away_team_container}>
                        <img
                          src={teamStatistics?.awayTeamLogo}
                          className={classes.team_logo}
                          alt={awayTeamStatistics[0]?.team?.name}
                        />
                        <p className={classes.name}>
                          {teamStatistics?.awayTeamName}
                        </p>
                      </div>
                    </div>
                    <div className={classes.stats_sub_container}>
                      <MatchComparison
                        teamStatistics={teamStatistics}
                        homeTeamStatistics={homeTeamStatistics}
                        awayTeamStatistics={awayTeamStatistics}
                      />
                    </div>
                  </div>
                )}
              </TabPanel>
              <TabPanel
                header={
                  lang == "english"
                    ? "Events"
                    : lang == "spanish"
                    ? "Eventos"
                    : lang == "french"
                    ? "Événements"
                    : lang == "arabic"
                    ? "الأحداث"
                    : "Events"
                }
              >
                <div className={classes.grid_col_2}>
                  <MatchEvents
                    team_name={homeTeamName}
                    data={homeTeamEvents2}
                  />
                  <MatchEvents
                    team_name={awayTeamName}
                    data={awayTeamEvents2}
                  />
                </div>
                <Commentry events={data?.events} />
              </TabPanel>
              <TabPanel
                header={
                  lang == "english"
                    ? "Form"
                    : lang == "spanish"
                    ? "Forma"
                    : lang == "french"
                    ? "Formulaire"
                    : lang == "arabic"
                    ? "استمارة"
                    : "Form"
                }
              >
                {matchPrediction?.homeTeamForm?.length > 0 &&
                  matchPrediction?.awayTeamForm?.length > 0 && (
                    <div className={classes.grid_col_2}>
                      <LastMatchesResults
                        team_name={data?.homeTeam}
                        data={matchPrediction?.homeTeamForm}
                      />
                      <LastMatchesResults
                        team_name={data?.awayTeam}
                        data={matchPrediction?.awayTeamForm}
                      />
                    </div>
                  )}
              </TabPanel>
            </TabView>
          </div>
        </>
      )}
      <PlayerInfoModal />
    </Layout>
  );
};

export default MatchPage;
