import React from "react";
import classes from "./MessageBoxSimple.module.css";

const MessageBoxSimple = ({ title, type }) => {
  return (
    <>
      {type === "New Season Update" ? (
        <div className={classes.message_box}>
          <p className={classes.message_starting}>{title}</p>
          <div className={classes.wrapper}>
            <img
              className={`${classes.item} ${classes.item1}`}
              src="/assets/images/teamLogos/qatar.png"
            />
            <img
              className={`${classes.item} ${classes.item2}`}
              src="/assets/images/teamLogos/uae.png"
            />
            <img
              className={`${classes.item} ${classes.item3}`}
              src="/assets/images/teamLogos/yemen.png"
            />
            <img
              className={`${classes.item} ${classes.item4}`}
              src="/assets/images/teamLogos/saudia.png"
            />
            <img
              className={`${classes.item} ${classes.item5}`}
              src="/assets/images/teamLogos/oman.png"
            />
            <img
              className={`${classes.item} ${classes.item6}`}
              src="/assets/images/teamLogos/bahrain.png"
            />
            <img
              className={`${classes.item} ${classes.item7}`}
              src="/assets/images/teamLogos/kuwait.png"
            />
            <img
              className={`${classes.item} ${classes.item8}`}
              src="/assets/images/teamLogos/iraq.png"
            />
          </div>
        </div>
      ) : (
        <div className={classes.message_box}>
          <p className={classes.message}>{title}</p>
        </div>
      )}
    </>
  );
};

export default MessageBoxSimple;
