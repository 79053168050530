import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import MatchLayout from "../components/MatchLayout";
import { baseUrl, weeklyMatchesApi } from "../api/api";
import axios from "axios";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import classes from "../css/HomePage.module.css";
import date from "../helpers/date";
import { useSelector } from "react-redux";
import MatchGridLayout from "../components/Match-Container/MatchGridLayout";
import HeadingWithGrid from "../components/Headings/HeadingWithGrid";
import MessageBoxSimple from "../components/Message-Box/MessageBoxSimple";

const UpcomingPage = () => {
  const [loading, setLoading] = useState(true);
  const [todayMatches, setTodayMatches] = useState([]);
  const [tomorrowMatches, setTomorrowMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);

  const { lang } = useSelector((state) => state.langSlice);

  const fetchUpcomingMatches = async (loader) => {
    try {
      if (loader?.loading == true) {
        setLoading(true);
      }
      const res = await axios.post(`${baseUrl}${weeklyMatchesApi}`, {
        timezone: moment.tz.guess(),
      });
      console.log(res.data.response, "response weekly matches");
      if (res?.data?.response?.length > 0) {
        // setUpcomingMatches(
        //   res?.data?.response.filter(
        //     (match) =>
        //       date(match?.time)?.date != "Today" &&
        //       date(match?.time)?.date != "Tomorrow"
        //   )
        // );

        setUpcomingMatches(
          res?.data?.response
            ?.filter(
              (match) =>
                date(match?.time)?.date !== "Today" &&
                date(match?.time)?.date !== "Tomorrow"
            )
            ?.sort((a, b) => {
              const dateA = new Date(a.time); // Parse date for sorting
              const dateB = new Date(b.time);
              return dateA - dateB; // Ascending order: Earlier dates come first
            })
        );
        
        setTodayMatches(
          res?.data?.response.filter(
            (match) => date(match?.time)?.date == "Today"
          )
        );
        setTomorrowMatches(
          res?.data?.response.filter(
            (match) => date(match?.time)?.date == "Tomorrow"
          )
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchUpcomingMatches({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchUpcomingMatches({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={3}>
      <Drawer active={3} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <MatchGridLayout>
            <HeadingWithGrid
              title={
                lang == "english"
                  ? "Gulf Cup Season 2024"
                  : lang == "spanish"
                  ? "Temporada de la Copa del Golfo 2024"
                  : lang == "french"
                  ? "Coupe du Golfe Saison 2024"
                  : lang == "arabic"
                  ? "كأس الخليج موسم 2024"
                  : "Gulf Cup Season 2024"
              }
            />
            <MessageBoxSimple
              title={
                lang == "english"
                  ? "New Season Starting from 21st December 2024"
                  : lang == "spanish"
                  ? "Nueva temporada a partir del 21 de diciembre de 2024"
                  : lang == "french"
                  ? "Nouvelle saison à partir du 21 décembre 2024"
                  : lang == "arabic"
                  ? "الموسم الجديد يبدأ من 21 ديسمبر 2024"
                  : "New Season Starting from 21st December 2024"
              }
              type="New Season Update"
            />

            {todayMatches?.map((match, index) => {
              return <MatchLayout match={match} key={index} />;
            })}
            {tomorrowMatches?.map((match, index) => {
              return <MatchLayout match={match} index={index} />;
            })}
            {upcomingMatches?.length > 0
              ? upcomingMatches?.map((match, index) => {
                  return <MatchLayout match={match} key={index} />;
                })
              : null
                // <MessageBoxSimple
                //   title={
                //     lang == "english"
                //       ? "There are no upcoming matches currently in this week.."
                //       : lang == "spanish"
                //       ? "No hay próximos partidos actualmente en esta semana."
                //       : lang == "french"
                //       ? "Il n’y a pas de matchs à venir cette semaine."
                //       : lang == "arabic"
                //       ? "لا توجد مباريات قادمة حاليا في هذا الأسبوع.."
                //       : "There are no upcoming matches currently in this week.."
                //   }
                // />
            }
          </MatchGridLayout>
        </>
      )}
    </Layout>
  );
};

export default UpcomingPage;
