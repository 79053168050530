import React from "react";
import classes from "./HeadingWithGrid.module.css";

const HeadingWithGrid = ({ title, type }) => {
  return (
    <div
      className={`${classes.heading_container} ${
        type === "New Season Update" && classes.different
      }`}
    >
      <h1 className={`${classes.heading} ${type==="New Season Update" && classes.heading_different}`}>{title}</h1>
    </div>
  );
};

export default HeadingWithGrid;
